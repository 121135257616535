import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import BlogCard from '../components/BlogCard';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';

const Blog = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog</title>
      </Helmet>
      <section id="blog" className="pt-20">
        <div className="container mx-auto lg:w-2/5 w-full">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none  text-center mb-3">
              Blog
            </h1>
            <p className="text-center text-xl mb-8">
              Alle Informationen über re.cycular und die Spermüllabholung - unser Produkt,
              Branchenkenntnisse, Partnerschaften und Veranstaltungen.
            </p>
            <br />
          </div>
        </div>
        <div className="container mx-auto lg:w-2/4 md:w-3/4 w-full px-2">
          {/* {data.posts.edges.map(post => (
            <BlogCard post={post} key={post.node.title} />
          ))} */}
        </div>
      </section>
    </Layout>
  );
};

export default Blog;

// export const PageQuery = graphql`
//   query {
//     posts: allContentfulBlogPost {
//       edges {
//         node {
//           createdAt
//           title
//           teaser
//           slug
//           bild {
//             gatsbyImageData(placeholder: BLURRED)
//           }
//         }
//       }
//     }
//   }
// `;
